<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
         <div class="field">
            <label>Territory Name</label>
            <InputText v-model="item.territory_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.territory_name }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.territory_name" >{{ errorEdit.territory_name[0] }}</small >
        </div>
        <div class="field">
            <label>Territory Type</label>
            <Dropdown dataKey="label" 
                    ref="dtt" v-model="item.terrritory_type" 
                    :loading="loadingDropdownTerritoryType" 
                    :options="dataDropdownTerritoryType" 
                    :class="{ 'p-invalid': errorEdit.territory_type }" 
                    optionLabel="label" optionValue="label" 
                    placeholder="Select Territory Type" :filter="true" 
                    :showClear="true" @filter="searchDropdownTerritoryType($event,'edit')"
                    @change="changeTerritoryUpperLevel()"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.territory_type" >{{ errorEdit.territory_type[0] }}</small>
        </div>
        <div class="field">
            <label>Territory Upper Level</label>
            <Dropdown dataKey="code" 
                    ref="dtul" v-model="item.territory_upper_id" 
                    :loading="loadingDropdownTerritory" 
                    :options="dataDropdownTerritory" 
                    :class="{ 'p-invalid': errorEdit.territory_upper_level }" 
                    optionLabel="label" optionValue="territory_id" 
                    placeholder="Select Territory Upper Level" :filter="true" 
                    :showClear="true" @filter="searchDropdownTerritoryUpperLevel($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.territory_upper_level" >{{ errorEdit.territory_upper_level[0] }}</small>
        </div>
         <div class="field">
            <label>Territory Status</label>
            <Dropdown v-model="item.territory_status" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Select Status" :class="{ 'p-invalid': errorEdit.territory_status }"/>
                <small class="p-invalid" style="color: red" v-if="errorEdit.territory_status" >{{ errorEdit.territory_status[0] }}</small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownTerritoryType: false,
            loadingDropdownTerritory: false,

            // dataDropdown
            dataDropdownTerritoryType: null,
            dataDropdownTerritory: null,
            dataDropdownStatus: [
                {name: 'ACTIVE', code: 'y'},
                {name: 'NON ACTIVE', code: 'n'},
            ],

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.searchDropdownTerritoryType('', 'edit', this.item.terrritory_type);
                        this.searchDropdownTerritoryUpperLevel('', 'edit', this.item.territory_upper_name);
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownTerritoryType(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.dtt.filterValue = valueEdit;
                }

                if(purpose == "edit"){
                    this.loadingDropdownTerritoryType = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/territory-type',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "edit"){
                        this.dataDropdownTerritoryType = res.data.data;
                        this.loadingDropdownTerritoryType = false;
                    }else if(purpose == null){
                        this.dataDropdownTerritoryType = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeTerritoryUpperLevel() {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.territory_upper_id = null;
            this.dataDropdownTerritory = null;
            this.searchDropdownTerritoryUpperLevel('');
        },
        searchDropdownTerritoryUpperLevel(event, purpose, valueEdit){
            setTimeout(() => {
                if(this.item.terrritory_type){
                    if(valueEdit){
                        this.$refs.dtul.filterValue = valueEdit;
                    }

                    if(purpose == "edit"){
                        this.loadingDropdownTerritory = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-territory3',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "terrritory_type" : this.item.terrritory_type
                        }
                    })
                    .then(res => {

                        if(purpose == "edit"){
                            this.dataDropdownTerritory = res.data.data;
                            this.loadingDropdownTerritory = false;
                        }else if(purpose == null){
                            this.dataDropdownTerritory = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/territory-user/update',
                data: {
                    "territory_id": this.item.territory_id,
                    "territory_name": this.item.territory_name,
                    "territory_type": this.item.terrritory_type,
                    "territory_upper_level": this.item.territory_upper_id,
                    "territory_status" : this.item.territory_status,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
		},
    }
}
</script>