<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Territory Name</label>
                <InputText v-model="forms.territory_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.territory_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.territory_name" >{{ errorAdd.territory_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Territory Type</label>
                    <Dropdown dataKey="label" v-model="forms.territory_type" :loading="loadingDropdownTerritoryType"
                        :options="dataDropdownTerritoryType" :class="{ 'p-invalid': errorAdd.territory_type }"
                        optionLabel="label" optionValue="label" placeholder="Select Territory Type"
                        :filter="true" :showClear="true" @filter="searchDropdownTerritoryType($event, 'add')" @change="changeTerritoryUpperLevel()"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.territory_type">{{
                        errorAdd.territory_type[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Territory Upper Level</label>
                    <Dropdown dataKey="territory_id" v-model="forms.territory_id" :loading="loadingDropdownTerritory"
                        :options="dataDropdownTerritory" :class="{ 'p-invalid': errorAdd.territory_upper_level }"
                        optionLabel="label" optionValue="territory_id" placeholder="Select Territory Upper Level"
                        :filter="true" :showClear="true" @filter="searchDropdownTerritory($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.territory_upper_level">{{
                        errorAdd.territory_upper_level[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownTerritoryType: false,
            loadingDropdownTerritory: false,

            // dataDropdown
            dataDropdownTerritoryType: null,
            dataDropdownTerritory: null,

            // addNew
            forms: {
                territory_name: null,
                territory_type: null,
                territory_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownTerritoryType('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownTerritoryType(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.dtt.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownTerritoryType = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/territory-type',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownTerritoryType = res.data.data;
                        this.loadingDropdownTerritoryType = false;
                    }else if(purpose == null){
                        this.dataDropdownTerritoryType = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeTerritoryUpperLevel() {
            this.forms.territory_id = null;
            this.dataDropdownTerritory = null;
            this.searchDropdownTerritory('');
        },
        searchDropdownTerritory(event, purpose, valueEdit){
            setTimeout(() => {
                if(this.forms.territory_type){
                    
                    if(valueEdit){
                        this.$refs.dterritory.filterValue = valueEdit;
                    }

                    if(purpose == "add"){
                        this.loadingDropdownTerritory = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-territory3',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "terrritory_type" : this.forms.territory_type
                        }
                    })
                    .then(res => {

                        if(purpose == "add"){
                            this.dataDropdownTerritory = res.data.data;
                            this.loadingDropdownTerritory = false;
                        }else if(purpose == null){
                            this.dataDropdownTerritory = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                }
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/territory-user/create',
                data: {
                    "territory_name": this.forms.territory_name,
                    "territory_type": this.forms.territory_type,
                    "territory_upper_level": this.forms.territory_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.territory_name = null;
            this.forms.territory_type = null;
            this.forms.territory_id = null;

            this.dataDropdownTerritory = null;
        },
    }
}
</script>